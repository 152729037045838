<template>
  <div class="Admins content">
    <v-container v-if="!isEdit">
      <v-card>
        <v-card-text>
          <div class="bar">
            <div class="bar">
              <TextSearch></TextSearch>
            </div>
            <v-btn @click="isNewItem = true" x-large color="secondary">{{
              $t("agent.newAgent")
            }}</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
    <v-container v-if="!isEdit">
      <v-data-table hide-default-footer :headers="headers" :items="table">
        <template v-slot:item="row">
          <tr>
            <td class="text-xs-right">{{ row.item.name }}</td>
            <td class="text-xs-right">{{ row.item.email }}</td>
            <td class="text-xs-right">
              <v-chip>{{ row.item.role }}</v-chip>
            </td>
            <td class="text-xs-right">{{ row.item.createdAt }}</td>
            <td class="text-xs-right">
              <v-btn
                icon
                v-if="table.length > 1"
                @click="deleteItem(row.item)"
                elevation="1"
              >
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
        <template v-slot:no-data>
          <p>{{ $t("app.noData") }}</p>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("app.deleteWarning") }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">
            {{ $t("app.cancel") }}
          </v-btn>
          <v-btn @click="finalize()" color="red lighten-1">
            {{ $t("app.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isNewItem" max-width="600">
      <v-card>
        <v-card-title> Új Felhasználó </v-card-title>
        <v-card-text>
          <v-spacer></v-spacer>
          <v-form v-model="isNewItemValid">
            <v-text-field
              :label="$t('agent.lastName')"
              outlined
              v-model="adminModel.firstName"
              required
              :rules="defaultRules"
            ></v-text-field>
            <v-text-field
              :label="$t('agent.firstName')"
              outlined
              v-model="adminModel.lastName"
              required
              :rules="defaultRules"
            ></v-text-field>
            <v-text-field
              :label="$t('agent.phone')"
              outlined
              v-model="adminModel.telephoneNumber"
              type="number"
              required
              :rules="defaultRules"
            ></v-text-field>
            <v-text-field
              :label="$t('form.email')"
              placeholder="*****@hellenergy.hu
"
              outlined
              required
              :rules="emailRules"
              v-model="adminModel.email"
            ></v-text-field>
            <v-select
              v-model="adminModel.role"
              :items="['Support', 'Admin']"
              :label="$t('agent.role')"
              required
              :rules="defaultRules"
            >
            </v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-card-actions>
            <v-btn
              :disabled="!isNewItemValid"
              x-large
              color="secondary"
              @click="submit()"
            >
              {{ $t("form.send") }}
            </v-btn>
            <v-btn x-large @click="reset()">
              {{ $t("form.cancel") }}
            </v-btn>
          </v-card-actions>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import messageBus from "../messageBus.js";
import eventBus from "../eventBus.js";
import TextSearch from "../components/TextSearch.vue";
import moment from "moment";
import { API_BASE } from "../util/constants.js";
// form validation rules
import { fv } from "../util/functions.js";
// import eventBus from "../messageBus.js";
import { getLoggedInHeader } from "../util/functions";
const ADMIN_MODEL = {
  email: null,
  firstName: null,
  lastName: null,
  role: null,
  telephoneNumber: null,
};
export default {
  name: "AdminUsers",
  components: {
    TextSearch,
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("agent.name"),
          sortable: false,
        },
        {
          text: this.$t("form.email"),
          sortable: false,
        },
        {
          text: this.$t("agent.role"),
          sortable: false,
        },
        {
          text: this.$t("agent.createdOn"),
          sortable: false,
        },
        {
          text: this.$t("app.actions"),
          sortable: false,
        },
      ],
      defaultRules: [(v) => !!v || "Kötelező"],
      emailRules: fv.emailRules,
      isNewItem: false,
      isNewItemValid: false,
      data: [],
      activeItem: null,
      activeItems: [],
      dialogs: [],
      dialog: false,
      isEdit: false,
      currentPage: 1,
      local: {
        isPending: false,
      },
      filter: {
        keyword: "Keres",
        pending: true,
      },
      adminModel: Object.assign(ADMIN_MODEL, {}),
    };
  },
  created() {
    eventBus.$on("DO_SEARCH", (data) => {
      this.doSearch(data);
    });
    eventBus.$on("QUIT_EDIT", (data) => {
      this.activeItem = null;
      this.isEdit = false;
    });
  },
  mounted() {
    this.getList();
  },
  computed: {
    table() {
      let a = [];
      this.data.forEach((item) => {
        let obj = {};
        obj.name = `${item.lastName} ${item.firstName}`;
        obj.id = item.id;
        obj.email = item.email;
        obj.telephoneNumber = item.telephoneNumber;
        obj.role = item.role === "ROLE_ADMIN" ? "Admin" : "Support";
        obj.createdAt = moment.unix(item.createdAt).format("YYYY.MM.DD");
        a.push(obj);
      });
      return a;
    },
    activeItemOriginal() {
      if (!this.activeItem) return null;

      return this.data.find((item) => item.id == this.activeItem.id);
    },
  },
  methods: {
    getList() {
      this.$store
        .dispatch("getCollection", {
          end: `admin/admin-users/page/${this.currentPage}/filtered?keyword=`,
          data: null,
        })
        .then((res) => {
          this.data = res.data;
        });
    },
    async doSearch(data) {
      if (data.length > 1) {
        this.filter.keyword = data;
      } else {
        this.filter.keyword = "";
      }
      this.filter.pending = this.local.isPending;
      var myHeaders = getLoggedInHeader(localStorage.getItem("token"));
      var raw = JSON.stringify(this.filter);
      var requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      let res = await fetch(
        `${API_BASE}admin/client-users/filtered`,
        requestOptions
      );
      if (res.ok) {
        const jsonResponse = await res.json();
        if (jsonResponse.length > 0) {
          this.data = jsonResponse;
        } else {
          messageBus.$emit("onNewMessage", {
            type: "error",
            label: this.$t("message.noResult"),
          });
        }
      } else {
        messageBus.$emit("onNewMessage", {
          type: "error",
          label: this.$t("message.error"),
        });
      }
    },
    handlePagination() {
      this.getList();
    },
    deleteItem(item) {
      this.activeItem = item;
      this.dialog = true;
    },
    editItem(item) {
      this.isEdit = true;
      this.activeItem = item;
    },
    finalize() {
      this.$store
        .dispatch("removeItem", {
          end: `admin/admin-users/${this.activeItem.id}`,
        })
        .then((res) => {
          messageBus.$emit("onNewMessage", {
            type: "success",
            label: this.$t("message.success"),
          });
          this.getList();
          this.activeItem = null;
          this.dialog = false;
        });
    },
    submit() {
      this.adminModel.role =
        this.adminModel.role === "Admin" ? "ROLE_ADMIN" : "ROLE_SUPPORT";

      this.$store
        .dispatch("addNewItem", {
          end: "admin/admin-users",
          data: this.adminModel,
        })
        .then((res) => {
          this.isNewItem = false;
          this.getList();
        });
    },
    reset() {
      console.log("reset");
      this.isNewItem = false;
      this.adminModel = {
        email: null,
        firstName: null,
        lastName: null,
        role: null,
        telephoneNumber: null,
      };
    },
  },
};
</script>
